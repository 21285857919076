import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

interface IRootProps extends BoxProps {
  dark?: boolean
}

export const Root = styled(Box, {
  shouldForwardProp: youShallNotPass('dark'),
})<IRootProps>(({ theme, ...props }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  color: theme.tokens.ThemeColorCommonPrimary,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('md')]: {
    width: '800px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.tokens.ThemeColorCommonSeparator,
  },
  ...(props.dark && {
    color: '#c2fab9',
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
  }),
}))
