import { deleteCookie, getCookie } from 'cookies-next'
import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { getCurrentDomain } from '../../helpers/getCurrentDomain/getCurrentDomain'
import { logError } from '../../tracking/datadog'
import { DeleteTokenCookieHook_signOutDocument } from './useDeleteTokenCookie.graphql'

type TOptions = Parameters<typeof deleteCookie>[1]

export const useDeleteTokenCookie = () => {
  const [signOut] = useMutation(DeleteTokenCookieHook_signOutDocument)

  return useCallback(async () => {
    const token = getCookie('token')

    if (!token) {
      return
    }

    try {
      await signOut()
    } catch (err) {
      logError(err, { message: 'Failed to sign out' })
    }

    const options: TOptions = {}

    // Look at the implementation for the setTokenCookie for
    // details on the "domain" and "path" props
    if (process.env.REDIRECT_TO_BRAND) {
      options.domain = getCurrentDomain()
      options.path = '/'
    }

    // make sure token without additional params is also deleted
    deleteCookie('token')
    deleteCookie('token', options)
  }, [])
}
