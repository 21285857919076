export const externalLinks = {
  scheduleDemo: 'https://meetings.hubspot.com/sanaa3/settle',
  applicationsEmail: 'applications@settle.co',
  supportEmail: 'support@settle.co',
  creditSimulator: 'https://capitalcalc.settle.com',
  rolesLearnMore: 'https://intercom.help/settleco/en/articles/6137326-assigning-user-roles',
  googleAuthenticatorIOS: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  googleAuthenticatorAndroid:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US&pli=1',
  settingUpMFA: 'https://help.settle.com/en/articles/6801735-setting-up-multi-factor-authentication-mfa',
  addingAndImportingBills: 'https://intercom.help/settleco/en/articles/6137366-adding-and-importing-invoices',
  plaid: {
    root: 'https://plaid.com',
    why: 'https://plaid.com/why-is-plaid-involved',
    apps: 'https://plaid.com/discover-apps',
  },
  finicity: {
    root: 'https://www.finicity.com',
    secure: 'https://www.finicity.com/pay',
    stories: 'https://www.finicity.com/customer-stories',
  },
}

export const marketingLinks = {
  marketingRoot: 'https://www.settle.com',
  privacyPolicy: 'https://www.settle.com/privacy-policy',
  workingCapital: 'https://www.settle.com/working-capital',
  purchaseOrders: 'https://www.settle.com/purchase-orders',
  unsupportedIndustries: 'http://www.settle.com/unsupported-industries',
}

export const looms = {
  purchasingIntroduction: 'https://www.loom.com/embed/fe28075c0caf4bcaa9792d98c02818c7',
  billPayProductTour: 'https://www.loom.com/embed/44b4faf5803c44c69d049f594fc9cabd?t=0',
}
