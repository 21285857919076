import { FC } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { getYear } from 'date-fns'
import phoneNumber from 'lib/consts/phoneNumber'
import { ERouterPage } from 'lib/navigation/consts'
import { marketingLinks } from 'lib/consts/externalLinks'
import * as $ from './Footer.styled'

export interface IFooterProps {
  className?: string
  supportContactInfo?: boolean
}

export const Footer: FC<IFooterProps> = ({ className, supportContactInfo = false }) => {
  const { t } = useTranslation()

  return (
    <$.Root className={className}>
      {supportContactInfo && (
        <Typography variant="body2" color="textSecondary" align="center">
          {t('questionsOrIssuesPhoneNumber', { phoneNumber: phoneNumber.text })}
        </Typography>
      )}
      <$.Links>
        <$.Text>Settle © {getYear(new Date())}</$.Text>
        <$.Link href={ERouterPage.termsOfService}>{t('termsOfService')}</$.Link>
        <$.Link href={marketingLinks.privacyPolicy}>{t('privacy')}</$.Link>
      </$.Links>
    </$.Root>
  )
}
