import NextLink from 'next/link'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'settle-ui/components/Link/Link'
import { SettleLogo } from 'settle-ui/components/SettleLogo/SettleLogo'
import { useLogout } from 'lib/hooks/useLogout/useLogout'
import { marketingLinks } from 'lib/consts/externalLinks'
import { useHomeHref } from './hooks/useHomeHref/useHomeHref'
import * as $ from './Header.styled'

interface ILink {
  id?: string
  text: string
  href: string
  target?: string
  onClick?(): void
}

export interface IHeaderProps {
  dark?: boolean
  loggedIn?: boolean
  showLogin?: boolean
  links?: ILink[]
  className?: string
}

export const Header: React.FC<IHeaderProps> = ({ dark, links, className, loggedIn, showLogin = true }) => {
  const { t } = useTranslation()
  const handleLogout = useLogout()
  const homeHref = useHomeHref()

  return (
    <$.Root maxWidth="md" component="header" dark={dark} className={className}>
      {(!process.env.BRAND || process.env.BRAND === 'settle') && (
        <Link color="secondary" href={loggedIn ? '/' : homeHref} title="Settle">
          <SettleLogo />
        </Link>
      )}
      {process.env.BRAND === 'escalon' && (
        <Link color="secondary" href="https://escalon.settle.co" title="Escalon, powered by Settle">
          <SettleLogo />
        </Link>
      )}
      {!links && (
        <Stack direction="row" spacing={3}>
          {!loggedIn && (
            <>
              <Link color="secondary" variant="body2" target="_blank" href={marketingLinks.marketingRoot}>
                {t('about')}
              </Link>
              {showLogin && (
                <Link color="secondary" variant="body2" id="header_login" href="/login" component={NextLink}>
                  {t('logIn')}
                </Link>
              )}
            </>
          )}
          {loggedIn && (
            <Link color="secondary" variant="body2" id="header_logout" onClick={handleLogout}>
              {t('logOut')}
            </Link>
          )}
        </Stack>
      )}
      {links && (
        <Stack direction="row" spacing={3}>
          {links.map((link) => {
            const { id, href, target, onClick } = link

            return (
              <Link
                variant="body1"
                key={href}
                id={id}
                href={href}
                target={target}
                onClick={onClick}
                component={NextLink}
              >
                {link.text}
              </Link>
            )
          })}
        </Stack>
      )}
    </$.Root>
  )
}
