import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbars } from 'settle-ui/hooks/useSnackbars/useSnackbars'
import type { ISnackbar } from 'settle-ui/components/Snackbars/Snackbars'

type TUrlBasedSnackbarOptions = Pick<NonNullable<ISnackbar['options']>, 'permanent'>

export const useUrlBasedSnackbar = (options?: TUrlBasedSnackbarOptions) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { permanent } = options ?? {}
  const { showWarning, showError, showAlert, showInfo } = useSnackbars()

  useEffect(() => {
    if (router.query.warning) {
      showWarning(router.query.warning.toString(), { permanent, id: 'url_based_warning' })

      delete router.query.warning
      router.replace({ query: router.query })
    }
  }, [router, t, showWarning, permanent])

  useEffect(() => {
    if (router.query.error) {
      showError(router.query.error.toString(), { permanent, id: 'url_based_error' })

      delete router.query.error
      router.replace({ query: router.query })
    }
  }, [router, t, showError, permanent])

  useEffect(() => {
    if (router.query.alert) {
      showAlert(router.query.alert.toString(), { permanent, id: 'url_based_alert' })

      delete router.query.alert
      router.replace({ query: router.query })
    }
  }, [router, t, showAlert, permanent])

  useEffect(() => {
    if (router.query.info) {
      showInfo(router.query.info.toString(), { permanent, id: 'url_based_info' })

      delete router.query.info
      router.replace({ query: router.query })
    }
  }, [router, t, showAlert, permanent, showInfo])
}
