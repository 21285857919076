export const getIsRefererSettleApp = (referrer: Maybe<string>) => {
  if (!referrer) {
    return false
  }

  const isSettleApp = /.*\.settle\.co\/?$/.test(referrer.replace('www.', ''))
  const isLocalhost = referrer.includes('localhost')

  return isSettleApp || isLocalhost
}
