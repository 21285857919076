import { useSnackbarsStore } from '../useSnackbarsStore/useSnackbarsStore'

export const useSnackbars = () => {
  const showAlert = useSnackbarsStore((store) => store.showAlert)
  const showError = useSnackbarsStore((store) => store.showError)
  const showWarning = useSnackbarsStore((store) => store.showWarning)
  const showInfo = useSnackbarsStore((store) => store.showInfo)
  const showApolloError = useSnackbarsStore((store) => store.showApolloError)
  const showApolloOrTextError = useSnackbarsStore((store) => store.showApolloOrTextError)
  const showSnackbar = useSnackbarsStore((store) => store.showSnackbar)
  const hideSnackbar = useSnackbarsStore((store) => store.hideSnackbar)

  return {
    showAlert,
    showError,
    showWarning,
    showInfo,
    showApolloError,
    showApolloOrTextError,
    showSnackbar,
    hideSnackbar,
  }
}
